<template>

    <!-- Structure -->
    <v-container style="height: 100% !important; max-width: 100% !important; padding-top: 0; overflow-x: none; overflow-y: auto; width: 100%; !important">
        
        <!-- Page d'accueil -->
        <v-row style="margin: 0; padding-top: 12px">
            <v-col :cols="cols_width">
                <v-row justify="end" style="margin: 0;">
                    <v-card class="green--text text--darken-3" light :width="card_width">
                        <v-img
                            src="../assets/img/ludo1.jpg"
                            aspect-ratio="1.8">
                        </v-img>
                        <v-card-title class="headline">Ludothèque</v-card-title>
                        <v-card-subtitle><br>Un lieu ouvert à tous où vous pouvez venir :</v-card-subtitle>
                        <v-card-text class="text-justify">
                            <ul>
                                <li>Jouer sur place seul, en famille, entre amis. Les enfants doivent être accompagnés d’un adulte jusqu’à l’âge de 8 ans. Gratuit et sans adhésion.</li>
                                <li>Emprunter jusqu’à 4 jeux par famille et en profiter chez vous pendant un mois. Tarif : adhésion (10€/an) + 1€ par jeu.</li>
                            </ul><br>
                            La ludothèque met à votre disposition plus de <a href="https://www.myludo.fr/#!/profil/la-ludotheque-du-trieves-9099" target="_blank">600 jeux de société</a> et jouets pour tous les âges.
                            <br><br>Des bénévoles passionnés de jeux sont disponibles pour vous conseiller, vous expliquer des règles, lancer une partie…
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-chip-group active-class="green--text text--darken-3" style="flex: 1 1 auto !important; justify-content: center;" v-model="location_1">
                                <v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">MENS</v-chip>
                                <v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">MONESTIER</v-chip>
                                <!--<v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">CLELLES</v-chip>-->
                            </v-chip-group>
                        </v-card-actions>
                        <v-expand-transition>
                            <div v-show="location_1 === 0">
                            <v-divider color="green"></v-divider>
                            <v-card-text class="text-justify" style="background-color: #E2ECE3; color: rgba(0, 0, 0, 0.6);">
                                Tous les mardis (sauf vacances scolaires) de 16h30 à 18h dans la salle Châtel, au rez-de-chaussée de l’espace culturel, face à la mairie de Mens. 
                                <br>
                            </v-card-text>
                            </div>
                        </v-expand-transition>
                        <v-expand-transition>
                            <div v-show="location_1 === 1">
                            <v-divider color="green"></v-divider>
                            <v-card-text class="text-justify" style="background-color: #E2ECE3; color: rgba(0, 0, 0, 0.6);">
                                Tous les mercredis (sauf 2e semaine des vacances scolaires) de 14h30 à 17h30 dans la salle Temps Libre, au rez-de-chaussée du Granjou à Monestier-de-Clermont. 
                                <br>
                            </v-card-text>
                            </div>
                        </v-expand-transition>
                        <v-expand-transition>
                            <div v-show="location_1 === 2">
                            <v-divider color="green"></v-divider>
                            <v-card-text class="text-justify" style="background-color: #E2ECE3; color: rgba(0, 0, 0, 0.6);">
                                Nouveau en 2022 ! Permanences mensuelles suivies d’une soirée jeux.
                                <br><br>De 17h30 à 19h30 les jeudis 22 septembre, 20 octobre, 17 novembre, 15 décembre, 23 mars, 20 avril, 18 mai, 15 juin, au Bistrot de la Place à Clelles.
                            </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                </v-row>
            </v-col>
            <v-col :cols="cols_width">
                <v-row justify="center" style="margin: 0;">
                    <v-card class="green--text text--darken-3" light :width="card_width">
                        <v-img
                            src="../assets/img/ludo3.jpg"
                            aspect-ratio="1.8">
                        </v-img>
                        <v-card-title class="headline">Soirées jeux</v-card-title>
                        <v-card-subtitle><br>Des soirées pour les adultes et les ados à partir de 12 ans.</v-card-subtitle>
                        <v-card-text class="text-justify">
                            Lors de ces soirées, des bénévoles apportent une sélection de jeux variés renouvelée chaque semaine. N’hésitez pas à apporter les vôtres pour les faire découvrir aux autres participants. 
                            <br/><br/>Tarif : la première soirée est gratuite, pour les suivantes, adhésion de 10€ par famille pour l’année scolaire.
                            <br/><br/>Une seule adhésion à l’association permet d’emprunter des jeux à la ludothèque et de participer aux soirées jeux dans les 2 antennes.
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-chip-group active-class="green--text text--darken-3" style="flex: 1 1 auto !important; justify-content: center;" v-model="location_2">
                                <v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">MENS</v-chip>
                                <v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">MONESTIER</v-chip>
                                <!--<v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">CLELLES</v-chip>-->
                            </v-chip-group>
                        </v-card-actions>
                        <v-expand-transition>
                            <div v-show="location_2 === 0">
                            <v-divider color="green"></v-divider>
                            <v-card-text class="text-justify" style="background-color: #E2ECE3; color: rgba(0, 0, 0, 0.6);">
                                Uniquement pendant la période hivernale, chaque 2ème vendredi du mois, de 18h30 à 23h, au rez-de-chaussée de l’espace culturel, face à la mairie de Mens.
                                <br><br>Prochains vendredis :<br><br>
                                <ul>
                                    <li>8 novembre 2024</li>
                                    <li>13 décembre 2024</li>
                                    <li>10 janvier 2025</li>
                                    <li>14 février 2025</li>
                                </ul> 
                            </v-card-text>
                            </div>
                        </v-expand-transition>
                        <v-expand-transition>
                            <div v-show="location_2 === 1">
                            <v-divider color="green"></v-divider>
                            <v-card-text class="text-justify" style="background-color: #E2ECE3; color: rgba(0, 0, 0, 0.6);">
                                Dans la salle des jeunes, 103 bis grand-rue à Monestier-de-Clermont (derrière La Fourmilière, à côté de la mairie).
                                <br><br>A 20h les vendredis soirs :<br><br>
                                <ul>
                                    <li>20 septembre 2024</li>
                                    <li>4 et 18 octobre 2024</li>
                                    <li>1er, 15 et 29 novembre 2024</li>
                                    <li>13 et 27 décembre 2024</li>
                                    <li>10 et 24 janvier 2025</li>
                                    <li>7 et 21 février 2025</li>
                                    <li>7 et 21 mars 2025</li>
                                    <li>4 et 18 avril 2025</li>
                                    <li>2, 16 et 30 mai 2025</li>
                                    <li>13 et 27 juin 2025</li>
                                </ul> 
                                <br/>Plus de renseignements sur Discord : <a href="https://discord.gg/G3TcKTxC3y" target="_blank">Dés Calés</a>.
                            </v-card-text>
                            </div>
                        </v-expand-transition>
                        <v-expand-transition>
                            <div v-show="location_2 === 2">
                            <v-divider color="green"></v-divider>
                            <v-card-text class="text-justify" style="background-color: #E2ECE3; color: rgba(0, 0, 0, 0.6);">
                                Nouveau en 2022 ! Soirées mensuelles précédées d’une permanence ludothèque.
                                <br><br>De 19h30 à 22h30 les jeudis 22 septembre, 20 octobre, 17 novembre, 15 décembre, 23 mars, 20 avril, 18 mai, 15 juin, au Bistrot de la Place à Clelles.
                            </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                </v-row>
            </v-col>
            <v-col :cols="cols_width">
                <v-row justify="start" style="margin: 0;">
                    <v-card class="green--text text--darken-3" light :width="card_width">
                        <v-img
                            src="../assets/img/ludo5.jpg"
                            aspect-ratio="1.8">
                        </v-img>
                        <v-card-title class="headline">Magic : The Gathering !</v-card-title>
                        <v-card-subtitle><br>Vous êtes des trièvois inconditionnels de Magic ou des magiciens en herbe souhaitant découvir ce jeu de cartes à collectionner ?</v-card-subtitle>
                        <v-card-text class="text-justify">
                            Rejoignez le groupe des Dés Calés et venez faire de la belle magie ! 
                            <br/><br/>Tarif : l'adhésion à l'association est requise et suivant l'évènement organisé, une participation aux frais est demandée. 
                            <br/><br/>Vous trouverez ci-dessous la liste des prochains évènements Magic organisés.
                            <br/><br/>Plus de renseignements sur Discord : <a href="https://discord.com/channels/883973978169942026/884108856249163796" target="_blank">Dés Calés</a>.
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-chip-group active-class="green--text text--darken-3" style="flex: 1 1 auto !important; justify-content: center;" v-model="location_3">
                                <v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">MONESTIER</v-chip>
                                <!--<v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">MONESTIER</v-chip>-->
                                <!--<v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">CLELLES</v-chip>-->
                            </v-chip-group>
                        </v-card-actions>
                        <v-expand-transition>
                            <div v-show="location_3 === 0">
                            <v-divider color="green"></v-divider>
                            <v-card-text class="text-justify" style="background-color: #E2ECE3; color: rgba(0, 0, 0, 0.6);">
                                Dans la salle des jeunes, 103 grand-rue à Monestier-de-Clermont (à côté de la mairie).
                                <br><br>Prochain draft (participation 10€) :<br><br>
                                <ul>
                                    <li>Pas encore de date programmée : à suivre !</li>
                                </ul> 
                            </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
        <v-row style="margin: 0; padding-top: 12px">
            <v-col :cols="cols_width">
                <v-row justify="end" style="margin: 0;">
                    <v-card class="green--text text--darken-3" light :width="card_width">
                        <v-img
                            src="../assets/img/ludo6.jpg"
                            aspect-ratio="1.8">
                        </v-img>
                        <v-card-title class="headline">Fantastique Festival</v-card-title>
                        <v-card-subtitle><br>
                            Chaque automne, un week-end entier de jeux est organisé à l’espace culturel de Mens.</v-card-subtitle>
                        <v-card-text class="text-justify">
                            Le 4e Fantastique Festival aura lieu les 11, 12 et 13 octobre 2024. Vous y retrouverez comme d'habitude tous les styles de jeux, tous les âges et toutes les ambiances !
                            <br/><br/>Plus de renseignements sur :
                            <br><a href="https://www.fantastiquefestival.ovh/" target="_blank">www.fantastiquefestival.ovh</a>.
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-chip-group active-class="green--text text--darken-3" style="flex: 1 1 auto !important; justify-content: center;" v-model="location_4">
                                <!--<v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">MONESTIER</v-chip>-->
                                <!--<v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">MONESTIER</v-chip>-->
                                <!--<v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">CLELLES</v-chip>-->
                            </v-chip-group>
                        </v-card-actions>
                        <v-expand-transition>
                            <div v-show="location_4 === 0">
                            <v-divider color="green"></v-divider>
                            <v-card-text class="text-justify" style="background-color: #E2ECE3; color: rgba(0, 0, 0, 0.6);">
                                Dans la salle des jeunes, 103 grand-rue à Monestier-de-Clermont (à côté de la mairie).
                                <br><br>Prochains drafts (participation 10€) :<br><br>
                                <ul>
                                    <li>8 mai 2023 à 9h : Dominaria Uni</li>
                                </ul> 
                            </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                </v-row>
            </v-col>
            <v-col :cols="cols_width">
                <v-row justify="center" style="margin: 0;">
                    <v-card class="green--text text--darken-3" light :width="card_width">
                        <v-img
                            src="../assets/img/ludo7.jpg"
                            aspect-ratio="1.8">
                        </v-img>
                        <v-card-title class="headline">Et encore...</v-card-title>
                        <v-card-subtitle><br>L'association des Dés Calés propose aussi :</v-card-subtitle>
                        <v-card-text class="text-justify">
                            <ul>
                                <li>Des ateliers jeu d'échecs pour tous niveaux, enfants et adultes, animés par un joueur chevronné à Monestier-de-Clermont ;</li>
                                <li>Des sessions d'initiation ou de perfectionnement au jeu de Go à Mens ;</li>
                                <li>Des tables de jeu de rôle, plus de renseignements sur Discord : <a href="https://discord.gg/G3TcKTxC3y" target="_blank">Dés Calés</a>.</li>
                            </ul> 
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-chip-group active-class="green--text text--darken-3" style="flex: 1 1 auto !important; justify-content: center;" v-model="location_5">
                                <v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">MENS</v-chip>
                                <v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">MONESTIER</v-chip>
                                <!--<v-chip class="elevation-2" color="grey lighten-5" label light text-color="green darken-3" style="font-weight:500;">CLELLES</v-chip>-->
                            </v-chip-group>
                        </v-card-actions>
                        <v-expand-transition>
                            <div v-show="location_5 === 0">
                            <v-divider color="green"></v-divider>
                            <v-card-text class="text-justify" style="background-color: #E2ECE3; color: rgba(0, 0, 0, 0.6);">
                                Jeu de Go
                                <br>Tous les mercredis de 17h30 à 19h30 au Café des Sports à Mens.
                            </v-card-text>
                            </div>
                        </v-expand-transition>
                        <v-expand-transition>
                            <div v-show="location_5 === 1">
                            <v-divider color="green"></v-divider>
                            <v-card-text class="text-justify" style="background-color: #E2ECE3; color: rgba(0, 0, 0, 0.6);">
                                Jeu d'échecs
                                <br>Tous les mercredis (sauf 2e semaine des vacances scolaires) de 14h à 16h dans la salle Temps Libre, au rez-de-chaussée du Granjou à Monestier-de-Clermont.
                            </v-card-text>
                            </div>
                        </v-expand-transition>
                        <v-expand-transition>
                            <div v-show="location_5 === 2">
                            <v-divider color="green"></v-divider>
                            <v-card-text class="text-justify" style="background-color: #E2ECE3; color: rgba(0, 0, 0, 0.6);">
                                Nouveau en 2022 ! Soirées mensuelles précédées d’une permanence ludothèque.
                                <br><br>De 19h30 à 22h30 les jeudis 22 septembre, 20 octobre, 17 novembre, 15 décembre, 23 mars, 20 avril, 18 mai, 15 juin, au Bistrot de la Place à Clelles.
                            </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>

    </v-container>

</template>

<script>

// Import éventuel d'autres composants - les rajouter dans 'components' et les appeler par balise : <name></name>

// Export du composant
export default {
    name: 'home',
    components: {
        //name
    },
    data() {
        return {
            location_1: null,
            location_2: null,
            location_3: null,
            location_4: null,
            location_5: null,
        }
    },
    computed: {
        card_width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '100%'
                case 'sm': return '100%'
                case 'md': return '95%'
                case 'lg': return '95%'
                case 'xl': return '70%'
                default: return '100%'
            }
        },
        cols_width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 12
                case 'sm': return 12
                case 'md': return 4
                case 'lg': return 4
                case 'xl': return 4
                default: return 12
            }
        },
        spacer_width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 12
                case 'sm': return 12
                case 'md': return 1
                case 'lg': return 1
                case 'xl': return 1
                default: return 12
            }
        },
    },       
    watch: {
        
    },
    methods: {
        
    }
}

</script>

<style>

/* Style du groupe des boutons de lieux */
.v-slide-group__content {
    justify-content: center !important;
}

</style>